import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import getLastUrlElement from '../../utils/getLastUrlElement'
import toast, { Toaster } from 'react-hot-toast'
import '../../assets/styles/Register.css'
import getGameFromUrl from '../../utils/getGameFromUrl'

const endpoint = process.env.REACT_APP_ENDPOINT

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const avatarMan = Object.values(importAll(require.context('../../assets/images/avatar/man', false, /\.(png|jpe?g|svg)$/)));
const avatarWoman = Object.values(importAll(require.context('../../assets/images/avatar/woman', false, /\.(png|jpe?g|svg)$/)));

const JoinRegister = () => {
    let navigate = useNavigate()
    const location = useLocation()
    const game = getGameFromUrl(location.pathname)
    const roomID = getLastUrlElement(location.pathname)
    const [activeAvatar, setActiveAvatar] = useState(avatarMan[0])
    const [activeCheckbox, setActiveCheckbox] = useState('man')

    useEffect(() => {
        console.log(activeAvatar)
    }, [activeAvatar])

    const joinRoom = () => {
        if (document.getElementById('username').value === '') {
            toast.error('Vous devez entrer un pseudo')
            return
        }
        const roomID = document.getElementById('room-code').value
        const username = document.getElementById('username').value
        axios.get(endpoint + "/getRoom?id=" + roomID).then(res => {
            const roomExist = res.data;
            if (roomExist) {
                navigate('/games/'+game+'/room/' + roomID, { state: { username: username, avatar: activeAvatar } })
            } else {
                alert("la partie que vous essayez de rejoindre n'existe pas")
            }
        })
    }

    const selectAvatar = event => {
        var img_avatar = event.target.getAttribute("src")
        setActiveAvatar(img_avatar)
    }

    const selectCheckbox = event => {
        var id_checkbox = event.target.id
        if (id_checkbox !== activeCheckbox) {
            setActiveCheckbox(id_checkbox)
            if (id_checkbox === 'man') {
                document.getElementById('avatar-man-list').style.display = 'grid'
                document.getElementById('avatar-woman-list').style.display = 'none'
                setActiveAvatar(avatarMan[0])
            } else {
                document.getElementById('avatar-man-list').style.display = 'none'
                document.getElementById('avatar-woman-list').style.display = 'grid'
                setActiveAvatar(avatarWoman[0])
            }
        }
    }

    return (

        <div className="register">
            <h1>{game}</h1>
            <div className="register-form">
                <div className="form-group">
                    <label>Pseudo</label>
                    <input id="username" type="text" name="username" maxLength={12} />
                </div>
                <div className="form-group">
                    <label>Choisis ton Avatar</label>
                    <div className="sexe-checkbox">
                        <AvatarCheckbox title="Homme" id="man" isActive={"man" === activeCheckbox} onClick={selectCheckbox}></AvatarCheckbox>
                        <AvatarCheckbox title="Femme" id="woman" isActive={"woman" === activeCheckbox} onClick={selectCheckbox}></AvatarCheckbox>
                    </div>
                    <div id="avatar-man-list" className='avatar-list'>
                        {avatarMan.map((man, i) => {
                            return (
                                <AvatarItem src={man} isActive={man === activeAvatar} onClick={selectAvatar} key={i}></AvatarItem>
                            )
                        })}
                    </div>
                    <div id="avatar-woman-list" className='avatar-list'>
                        {avatarWoman.map((woman, i) => {
                            return (
                                <AvatarItem src={woman} isActive={woman === activeAvatar} onClick={selectAvatar} key={i}></AvatarItem>
                            )
                        })}
                    </div>
                </div>
                <input id="room-code" type="hidden" name="room-code" value={roomID}/>
                <button onClick={joinRoom} type="submit" className="btn btn-join">REJOINDRE</button>
                <Toaster
                    toastOptions={{
                        success: {
                            duration: 1000
                        },
                        error: {
                            duration: 2000,
                            style: {
                                background: 'red',
                                color: 'white'
                            },
                        }
                    }}
                />
            </div>
        </div>
    );
}

const AvatarItem = ({ src, isActive, onClick }) => {
    return (
        <div className='avatar-item'>
            <div className={isActive ? 'avatar-outline' : null}></div>
            <img src={src} alt="" className={isActive ? 'active-avatar' : null} onClick={onClick} />
        </div>
    )
}

const AvatarCheckbox = ({ title, id, isActive, onClick }) => {
    return (
        <button id={id} className={isActive ? 'active-sexe' : null} onClick={onClick}>{title}</button>
    )
}
export default JoinRegister
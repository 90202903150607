/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import getLastUrlElement from '../../../utils/getLastUrlElement'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal';
import toast, { Toaster } from 'react-hot-toast'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faCrown, faCheck, faXmark, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import spyCard from '../../../assets/images/spyfall/spy.jpeg'
import gameLocations from './gameLocations'
import '../../../assets/styles/Spyfall.css'
import Select from 'react-select'

let socket
const ENDPOINT = process.env.REACT_APP_ENDPOINT
const Spyfall = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const username = location.state ? location.state.username : null
    const avatar = location.state ? location.state.avatar : null
    const roomID = getLastUrlElement(location.pathname)
    const roomLink = window.location.href
    const maxPlayers = 6
    const user_socket = sessionStorage.getItem("socket");
    const user = { name: username, avatar: avatar, socket: user_socket == null ? '' : user_socket }
    const lang = 'fr'

    const setOptions = [
        { value: 'set1', label: 'Standard 1' },
        { value: 'set2', label: 'Standard 2'}
    ]

    //Variables necessaires au fonctionnement du lobby
    const [users, setUsers] = useState([])
    const [room, setRoom] = useState({})
    const [roomValidity, setRoomValidity] = useState(true)
    const [pageLoading, setPageLoading] = useState(true)
    const [isHost, setIsHost] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [everybodyReady, setEverybodyReady] = useState(false)
    const [hostConnected, setHostConnected] = useState(false)
    const [timerLobby, setTimerLobby] = useState(5)
    const [currentUser, setCurrentUser] = useState({})
    const [loading, setLoading] = useState(false)

    //Variables necessaires au fonctionnement du jeu
    const [inGame, setInGame] = useState(false)
    const [gamePhase, setGamePhase] = useState('')
    const [isSpy, setIsSpy] = useState(false)
    const [role, setRole] = useState('')
    const [set, setSet] = useState('')
    const [gameLocationImageSrc, setGameLocationImageSrc] = useState('')
    const [locationText, setLocationText] = useState('')
    const [timerMinutes, setTimerMinutes] = useState('05')
    const [timerSeconds, setTimerSeconds] = useState('00')
    const [vote, setVote] = useState({})
    const [hasVoted, setHasVoted] = useState(false)
    const [locationGuess, setLocationGuess] = useState('')

    Modal.setAppElement('#root')


    const importAll = (r) => {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }

    const imageSet1 = Object.values(importAll(require.context('../../../assets/images/spyfall/locations/set1', false, /\.(png|jpe?g|svg|webp)$/)));

    const imageSet2 = Object.values(importAll(require.context('../../../assets/images/spyfall/locations/set2', false, /\.(png|jpe?g|svg|webp)$/)));

    const locations = {
        set1: imageSet1,
        set2: imageSet2
    }

    const formattedNumber = (n) => {
        return n > 9 ? "" + n : "0" + n;
    }

    useEffect(() => {

        const connectionOptions = {
            "forceNew": true,
            "transports": ["websocket"]
        }
        socket = io.connect(ENDPOINT, connectionOptions)
        socket.on('connect_error', (err) => {
            console.log(`connect_error due to ${err.message}`);
        })
        socket.on('connect_failed', (err) => {
            console.log(`connect_error due to ${err.message}`);
        })

        socket.emit('join room', roomID, user)

        //cleanup on component unmount
        return function cleanup() {
            //shut down connnection instance
            document.body.classList.remove('bg-wood');
            document.body.classList.remove('bg-spyfall-game');
            socket.close()
        }
    }, [])

    useEffect(() => {
        socket.on('invalid room', () => {
            setRoomValidity(false)
        })
        socket.on("need register", () => {
            navigate('/games/spyfall/room/join/' + roomID)
        })
        socket.on('reconnect', (room) => {
            setPageLoading(false)
            if (room.inGame) {
                sessionStorage.setItem("socket", socket.id)
                document.body.classList.add('bg-spyfall-game')
                setIsHost(room.host === socket.id ? true : false)
                setHostConnected(users.filter(u => u.socket === room.host).length === 0 ? false : true)
                setHasVoted(room.players.filter(p => p.socket === socket.id)[0].game.vote.hasVoted)
                setLocationGuess(room.players.filter(p => p.game.spy === true)[0].game.locationGuess)
                setGamePhase(room.game.phase)
                setSet(room.game.set)
                setIsSpy(room.players.filter(p => p.socket === socket.id)[0].game.spy)
                setRole(room.players.filter(p => p.socket === socket.id)[0].game.role)
                setTimerMinutes(formattedNumber(Math.floor(room.timer / 60)))
                setTimerSeconds(formattedNumber(room.timer % 60))
                for (var i = 0; i < locations[room.game.set].length; i++) {
                    if (locations[room.game.set][i].includes(room.game.location)) {
                        setGameLocationImageSrc(locations[room.game.set][i])
                        setLocationText(gameLocations[room.game.set][i][lang])
                        break;
                    }
                }
            }
        })
        socket.on('roomData', ({ room, users }) => {
            document.body.classList.add('bg-wood');
            document.body.classList.remove('bg-spyfall-game')
            sessionStorage.setItem("socket", socket.id)
            setPageLoading(false)
            setUsers(users)
            setSet(room.game.set)
            setCurrentUser(users.filter(u => u.socket === socket.id)[0])
            setIsHost(room.host === socket.id ? true : false)
            setHostConnected(users.filter(u => u.socket === room.host).length === 0 ? false : true)
            setEverybodyReady(users.filter(u => u.ready).length === users.length ? true : false)
            setIsReady(users.filter(u => u.socket === socket.id)[0].ready)
            setRoom(room)
            if (room.timer) { setTimerLobby(Math.round(room.timer / 60)) }
        })
        socket.on('initGameState', (room) => {
            document.body.classList.add('bg-spyfall-game')
            setLoading(false)
            setInGame(room.inGame)
            setGamePhase(room.game.phase)
            setUsers(room.players)
            setSet(room.game.set)
            setCurrentUser(room.players.filter(p => p.socket === socket.id)[0])
            setIsSpy(room.players.filter(p => p.socket === socket.id)[0].game.spy)
            setRole(room.players.filter(p => p.socket === socket.id)[0].game.role)
            setHasVoted(room.players.filter(p => p.socket === socket.id)[0].game.vote.hasVoted)
            setLocationGuess(room.players.filter(p => p.game.spy === true)[0].game.locationGuess)
            setTimerMinutes(formattedNumber(Math.floor(room.timer / 60)))
            setTimerSeconds(formattedNumber(room.timer % 60))
            for (var i = 0; i < locations[room.game.set].length; i++) {
                if (locations[room.game.set][i].includes(room.game.location)) {
                    setGameLocationImageSrc(locations[room.game.set][i])
                    setLocationText(gameLocations[room.game.set][i][lang])
                    break;
                }
            }
            setRoom(room)
            setVote(room.game.vote)
        })

        socket.on("updateGameState", (room) => {
            setInGame(room.inGame)
            setGamePhase(room.game.phase)
            setUsers(room.players)
            setCurrentUser(room.players.filter(p => p.socket === socket.id)[0])
            setRoom(room)
            setVote(room.game.vote)
            setHasVoted(room.players.filter(p => p.socket === socket.id)[0].game.vote.hasVoted)
            setLocationGuess(room.players.filter(p => p.game.spy === true)[0].game.locationGuess)
        })

        socket.on("update timer", (room) => {
            setRoom(room)
            setTimerMinutes(formattedNumber(Math.floor(room.timer / 60)))
            setTimerSeconds(formattedNumber(room.timer % 60))
        })

        socket.on("end timer", (room) => {
            if (room.game.phase === 'main') {
                setRoom(room)
            }
        })

        socket.on("end vote", (result) => {
            voteResult(result)
        })

        socket.on("loading game", () => {
            setLoading(true)
        })
        socket.on("leave", () => {
            navigate('/games/spyfall')
        })
    }, [])

    const voteResult = (result) => {
        if (result === 'no') {
            toast.error("Le vote n'a pas abouti")
        } else {
            toast.success("Le vote n'a pas abouti")
        }
    }

    const EmptySeat = () => {
        const emptySeat = [];
        for (var i = 0; i < maxPlayers - users.length; i++) {
            emptySeat.push(
                <li key={i} className="player-item empty">
                    <div className='player-img'>
                    </div>
                    <h3>VIDE</h3>
                </li>
            )
        }
        return (emptySeat)
    }

    const SwapReady = () => {
        socket.emit("ready", room)
    }
    const decrementTimer = () => {
        const timer = document.getElementById('input-timer')
        if (parseInt(timer.value) > parseInt(timer.getAttribute('min'))) {
            timer.value = parseInt(timer.value) - 1
            changeTimer()
        }
    }

    const incrementTimer = () => {
        const timer = document.getElementById('input-timer')
        if (parseInt(timer.value) < parseInt(timer.getAttribute('max'))) {
            timer.value = parseInt(timer.value) + 1
            changeTimer()
        }
    }

    const changeTimer = () => {
        const timer = parseInt(document.getElementById('input-timer').value) * 60
        socket.emit("spyfall timer", room, timer)
    }

    const changeSet = (option) => {
        const set = option.value
        socket.emit("spyfall set", room, set)
    }

    const startGame = () => {
        socket.emit("spyfall start game", room, users)
    }

    const votePlayer = (e) => {
        const player_id = e.currentTarget.parentElement.id
        socket.emit("spyfall player vote", room, player_id)
    }

    const sendVote = (e) => {
        const value = e.currentTarget.dataset.vote
        socket.emit('spyfall send vote', room, value)
    }

    const voteForSpy = (e) => {
        const player_num = parseInt(e.currentTarget.dataset.num)
        socket.emit('spyfall spy vote', room, player_num)
    }

    const guessLocation = (e) => {
        const guess = e.currentTarget.dataset.name
        socket.emit("spyfall guess location", room, guess)
    }

    const confirmLocationGuess = () => {
        if (locationGuess !== '') {
            socket.emit("spyfall confirm location guess", room, locationGuess.toLowerCase())
        }
    }

    const replay = () => {
        socket.emit('spyfall play again', room)
    }

    const leave = () => {
        socket.emit('quit room', room)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    if (!pageLoading) {
        if (!inGame) {
            return (
                <div className='room lobby spyfall-lobby'>
                    <div className='room-header'>
                        <div className='room-code'>
                            <div className='room-code-text'>Code d'invitation:</div>
                            <CopyButton text={roomID} />
                        </div>
                        <div className='room-code'>
                            <div className='room-code-text'>Lien d'invitation:</div>
                            <CopyButton text='Lien' link={roomLink} />
                        </div>
                    </div>
                    <div className='room-container'>
                        <div className='players'>
                            <h2>Joueurs</h2>
                            <ul>
                                {users.map((user, i) => (
                                    <li key={i} className="player-item">
                                        <div className='player-img'>
                                            <img src={user.avatar} alt=""></img>
                                        </div>
                                        <h3>{user.username}</h3>
                                        {user.socket === room.host ? <span className='host-icon'><FontAwesomeIcon icon={faCrown} /></span> : null}
                                        {user.ready ? <p className='ready'>PRÊT</p> : <p className='not-ready'>PAS PRÊT</p>}
                                    </li>
                                ))}
                                <EmptySeat />
                            </ul>
                        </div>
                        <div className='settings'>
                            <h2>SPYFALL</h2>
                            {!hostConnected ? <p className='warning'>L'hôte est absent</p> : !everybodyReady ? <p className='warning'>Tout les joueurs doivent être prêts</p> : <p className='good'>L'hôte peut lancer la partie</p>}
                            <div className='timer-container'>
                                <h3>Minuteur</h3>
                                <div className="timer">
                                    <div className="btn-timer btn-rm" onClick={isHost ? decrementTimer : null}>-</div>
                                    <input id="input-timer" className="input-timer" min="1" max="10" step="1" value={timerLobby} readOnly />
                                    <div className="btn-timer btn-add" onClick={isHost ? incrementTimer : null}>+</div>
                                </div>
                            </div>
                            <div className='set-container'>
                                <h3>Lieux</h3>
                                <Select options={setOptions} onChange={(value) => changeSet(value)} isDisabled={!isHost}className="set-select" value={setOptions.filter(o=>o.value === set)[0]} />
                            </div>
                            <div className="button-container">
                                {isReady ? <button className='btn btn-not-ready' onClick={SwapReady}>PAS PRÊT</button> : <button className='btn btn-ready' onClick={SwapReady}>PRÊT</button>}
                                {isHost ? <button className="btn btn-start" disabled={!everybodyReady ? true : false} onClick={startGame}>C'EST PARTI</button> : null}
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={loading}
                        style={customStyles}
                        contentLabel="Example Modal"
                        overlayClassName="loading-modal-overlay"
                        className="loading-modal-content"
                    >
                        <div className='modal-header'>
                            <h2>Chargement de la partie...</h2>
                        </div>
                        <div className='modal-body'>
                            <div className='loading'></div>
                        </div>
                    </Modal>
                </div>
            )
        } else { //La game a commencé
            if (gamePhase === 'main') {
                return (
                    <div className='room spyfall-game'>
                        <div className='room-header'></div>
                        <div className='room-container'>
                            <div className="timer-container">
                                <div className='timer'>{timerMinutes}:{timerSeconds}</div>
                            </div>
                            <h2>Lieu</h2>
                            <div className='location-container'>
                                <div className='location-card'>
                                    <img src={isSpy ? spyCard : gameLocationImageSrc} alt=""></img>
                                    {isSpy ? <h3>Spy</h3> : <h3>{locationText}</h3>}
                                </div>
                                {isSpy ? null : <h4><FontAwesomeIcon icon={faUserSecret} /> : {role}</h4>}
                            </div>

                            <div className='players'>
                                <ul>
                                    {users.map((player, i) => (
                                        <li key={i} id={player.socket} className="player-item">
                                            <div className='player-img' onClick={player.socket !== currentUser.socket ? votePlayer : null}>
                                                <img src={player.avatar} alt=""></img>
                                            </div>
                                            <h3>{player.username}</h3>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='locations-container'>
                                <h2>Lieux possibles</h2>
                                <div className='locations-grid'>
                                    {locations[set].map((loc, i) => {
                                        return (
                                            <LocationCell src={loc} name={gameLocations[set][i]['name']} text={gameLocations[set][i][lang]} key={i}></LocationCell>
                                        )
                                    })}
                                </div>
                            </div>
                            <Modal
                                isOpen={vote.inProgress}
                                style={customStyles}
                                contentLabel="Example Modal"
                                overlayClassName="vote-modal-overlay"
                                className="vote-modal-content"
                            >
                                <div className='modal-header'>
                                    <h2>Vote !</h2>
                                </div>
                                <div className='modal-body'>
                                    <p>{vote.src.username} lance un vote contre <br /><span className='vote-victim'>{vote.victim.username}</span></p>
                                    <div className='vote-results'>
                                        {users.map((player, i) => (
                                            <div key={i} className={i < vote.results.length ? `vote-cell ${vote.results[i]}` : "vote-cell"}></div>
                                        ))}
                                    </div>
                                    {!hasVoted ?
                                        <div className='vote-button-container'>
                                            <button data-vote="yes" className='vote-yes' onClick={sendVote}><FontAwesomeIcon icon={faCheck} /></button>
                                            <button data-vote="no" className='vote-no' onClick={sendVote}><FontAwesomeIcon icon={faXmark} /></button>
                                        </div>
                                        : <div className='voted'>A voté !</div>}
                                </div>
                            </Modal>
                            <Toaster
                                toastOptions={{
                                    success: {
                                        duration: 1000
                                    },
                                    error: {
                                        duration: 2000,
                                        style: {
                                            background: 'white',
                                            color: 'black'
                                        },
                                    }
                                }}
                            />
                        </div>
                    </div>
                )
            } else if (gamePhase === 'spy discover') {
                return (
                    <div className='room spyfall-game spy-guess'>
                        <div className='room-header'></div>
                        <div className='room-container'>
                            <h2>Vous avez trouvé l'espion</h2>
                            <p>il va pouvoir essayer de deviner le lieu pour esperer gagner</p>
                            <div className='locations-grid'>
                                {locations[room.game.set].map((loc, i) => {
                                    return (
                                        <LocationCellGuess src={loc} name={gameLocations[set][i]['name']} text={gameLocations[set][i][lang]} key={i} onClick={isSpy ? guessLocation : null} classname={locationGuess === gameLocations[set][i]['name'] ? 'location-cell guess' : 'location-cell'}></LocationCellGuess>
                                    )
                                })}
                            </div>
                            {isSpy ? <button className='btn btn-valider' onClick={confirmLocationGuess}>Valider</button> : null}
                        </div>
                    </div>
                )
            } else if (gamePhase === 'end') {
                return (
                    <div className='room spyfall-game end-game'>
                        <div className='room-header'></div>
                        <div className='room-container'>
                            <h2>Fin de la partie</h2>
                            {currentUser.win ? <p className="win">Vous avez gagné</p> : <p className="lose">Vous avez perdu</p>}
                            <div className='end-result'>
                                <div className='end-location'>
                                    <h3>Le lieu était</h3>
                                    <div className='location-card'>
                                        <img src={gameLocationImageSrc} alt=""></img>
                                        <h3>{locationText}</h3>
                                    </div>
                                </div>
                                <div className='end-spy'>
                                    <h3>L'espion était</h3>
                                    <div className='player'>
                                        <img src={users.filter(u => u.game.spy === true)[0].avatar} alt=""></img>
                                        <h3>{users.filter(u => u.game.spy === true)[0].username}</h3>
                                    </div>
                                </div>
                            </div>
                            {isHost ? <button className='btn btn-replay' onClick={replay}>Rejouer</button> : <div></div>}
                            {isHost ? <button className='btn btn-leave' onClick={leave}>Quitter</button> : <div></div>}
                        </div>
                    </div>
                )
            } else if (gamePhase === "spy vote") {
                return (
                    <div className='room spyfall-game spy-vote'>
                        <div className='room-header'></div>
                        <div className='room-container'>
                            <h2>Temps Ecoulé</h2>
                            <p>Vous devez voter pour celui que vous pensez être l'espion.
                                <br />Le vote prendra fin lorsque un joueur aura l'unanimité des voix</p>
                            <div className='players'>
                                {users.map((player, i) => (
                                    <div key={i} id={player.socket} data-num={i + 1} className={currentUser.game.voteSpy === i + 1 ? 'player target' : "player"} onClick={player.socket !== currentUser.socket ? voteForSpy : null}>
                                        <img src={player.avatar} alt=""></img>
                                        <h3>{player.username}</h3>
                                        <h4 className={users.filter(p => p.game.voteSpy === i + 1).length > 0 ? 'danger' : null}>{users.filter(p => p.game.voteSpy === i + 1).length}</h4>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    } else {
        if (!roomValidity) {
            return (<div style={{ marginTop: 100 }}>La partie que vous essayez de rejoindre n'existe pas</div>)
        } else {
            return (<div></div>)
        }
    }
}

const LocationCell = ({ src, name, text }) => {
    const [isActive, setIsActive] = useState(false)
    const Click = () => {
        isActive ? setIsActive(false) : setIsActive(true)
    }
    return (
        <div className='location-cell'
            data-name={name}
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${src})` }}
            onClick={Click}>
            {text}
            {isActive ? <div className='red-x'></div> : null}
        </div>
    )
}

const LocationCellGuess = ({ src, name, text, onClick, classname }) => {
    return (
        <div className={classname}
            data-name={name}
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${src})` }}
            onClick={onClick ? onClick : null}>
            {text}
        </div>
    )
}

const CopyButton = ({ text, link }) => {
    const [copySuccess, setCopySuccess] = useState(false)

    const copyToClipboard = (elem) => {
        if (link) {
            navigator.clipboard.writeText(link)
        } else {
            navigator.clipboard.writeText(elem.currentTarget.textContent)
        }
        setCopySuccess(true)
        setTimeout(() => {
            setCopySuccess(false)
        }, 1500)
    }

    return (
        <div className={copySuccess ? 'copy-code-button copy-success' : 'copy-code-button'} onClick={copyToClipboard}>
            {copySuccess ? <p>Copié</p> : <p>{text}</p>}
            {copySuccess ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCopy} />}
        </div>
    )
}

export default Spyfall
import './App.css'
import { Route, Routes } from 'react-router-dom'
import Homepage from './components/Homepage'
import Games from './components/Games'
import JoinRegister from './components/games/JoinRegister'
import Register from './components/games/Register'

import Spyfall from './components/games/spyfall/Spyfall'
//import Perudo from './components/games/perudo/Perudo'


const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/games' element={<Games />} />

        <Route path='/games/spyfall' element={<Register />} />
        <Route path='/games/spyfall/room/*' element={<Spyfall />} />
        <Route path='/games/spyfall/room/join/*' element={<JoinRegister />} />

        {/* <Route path='/games/perudo' element={<Register />} />
        <Route path='/games/perudo/room/*' element={<Perudo />} />
        <Route path='/games/perudo/room/join/*' element={<JoinRegister />} /> */}
      </Routes>
    </div>
  )
}

export default App

import React from 'react'
import spyfall from '../assets/images/spyfall/spyfall.webp'
import spyfallIcon from '../assets/images/spyfall/spyfall-icon.png'
import perudo from '../assets/images/perudo/perudo.png'
//import perudoIcon from '../assets/images/perudo/perudo-icon.png'
import '../assets/styles/Games.css'
import { Link } from 'react-router-dom'

const Games = () => {
    return (
        <div className="games">
            <h1>Liste des jeux</h1>
            <Link to="/games/spyfall" className="game-card">
                <img className="icon" src={spyfallIcon} alt=""></img>
                <img className="game-img" src={spyfall} alt=""></img>
            </Link>
            <Link to="/games" className="game-card coming-soon">
                {/* <img className="icon" src={perudoIcon} alt=""></img> */}
                <img className="game-img" src={perudo} alt=""></img>
                <div className='coming-soon'></div>
            </Link>
        </div>
    );
}
export default Games
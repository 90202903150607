const gameLocations = {
    set1: [
        { name: 'airplane', fr: 'Avion' },
        { name: 'bank', fr: 'Banque' },
        { name: 'beach', fr: 'Plage' },
        { name: 'casino', fr: 'Casino' },
        { name: 'cathedral', fr: 'Cathedrale' },
        { name: 'circus', fr: 'Cirque' },
        { name: 'corporate_party', fr: 'Séminaire Entreprise' },
        { name: 'crusader_army', fr: 'Armée des Croisés' },
        { name: 'day_spa', fr: 'Spa' },
        { name: 'embassy', fr: 'Embassade' },
        { name: 'hospital', fr: 'Hôpital' },
        { name: 'hotel', fr: 'Hôtel' },
        { name: 'military_base', fr: 'Base Militaire' },
        { name: 'movie_studio', fr: 'Studio de Cinéma' },
        { name: 'ocean_liner', fr: 'Paquebot' },
        { name: 'passenger_train', fr: 'Train de Passagers' },
        { name: 'pirate_ship', fr: 'Bateau Pirate' },
        { name: 'polar_station', fr: 'Station Polaire' },
        { name: 'police_station', fr: 'Station de Police' },
        { name: 'restaurant', fr: 'Restaurant' },
        { name: 'school', fr: 'Ecole' },
        { name: 'service_station', fr: 'Station Essence' },
        { name: 'space_station', fr: 'Station Spatiale' },
        { name: 'submarine', fr: 'Sous-Marin' },
        { name: 'supermarket', fr: 'Supermarché' },
        { name: 'theatre', fr: 'Théâtre' },
        { name: 'university', fr: 'Université' },
    ],
    set2:[
        { name: 'amusement_park', fr: "Parc d'attraction" },
        { name: 'bakery', fr: 'Boulanger' },
        { name: 'bar', fr: 'Bar' },
        { name: 'castle', fr: 'Chateau' },
        { name: 'cinema', fr: 'Cinema' },
        { name: 'desert', fr: 'Desert' },
        { name: 'escape_room', fr: 'Escape room' },
        { name: 'factory', fr: 'Usine' },
        { name: 'graveyard', fr: 'Cimetière' },
        { name: 'hair_salon', fr: 'Salon de coiffure' },
        { name: 'island', fr: 'Ile' },
        { name: 'kebab', fr: 'Kebab' },
        { name: 'library', fr: 'Bibliothèque' },
        { name: 'museum', fr: 'Musée' },
        { name: 'nightclub', fr: 'Discothèque' },
        { name: 'north_pole', fr: 'Pôle Nord' },
        { name: 'parc', fr: 'Parc' },
        { name: 'stadium', fr: 'Stade' },
        { name: 'subway', fr: 'Metro' },
        { name: 'swimming_pool', fr: 'Piscine' },
        { name: 'volcano', fr: 'Volcan' },
        { name: 'white_house', fr: 'Maison Blanche' },
        { name: 'zoo', fr: 'Zoo' },
    ]
}

export default gameLocations
import React from 'react'
import * as Color from '../assets/Color'
import '../assets/styles/Homepage.css'
import { Link } from 'react-router-dom'

const Homepage = () => {
    return (
        <div className="homepage">
            <h1>Bienvenue sur KRZ-Games</h1>
            <p>Ici, vous pourrez jouez avec vos amis sur nos différents jeux</p>
            <Link to="/games"><button className="btn" style={btn}>voir les jeux</button></Link>
        </div>
    );
}
const btn = {
    background: Color.red,
    elevation: 8,
    color: Color.white,
}

export default Homepage